import { toRaw } from "vue";
import api_request from "./../../../libs/sendRequest/sendRequest";
import magicJson from "./../../../magicJson";

class SettingsService {
  api_request = new api_request("/api/service");

  createConfig = async (data, file) => {
    try {
      magicJson.endpoint = "settings";
      magicJson.executor = "createConfig";
      magicJson.data = [data];

      const result = await this.api_request.sendRequest(magicJson, file);
      return result;
    } catch (error) {
      return error;
    }
  };

  updateConfig = async (data, file) => {
    try {
      magicJson.endpoint = "settings";
      magicJson.executor = "updateConfig";
      magicJson.data = [data];

      const result = await this.api_request.sendRequest(magicJson, file);
      return result;
    } catch (error) {
      return error;
    }
  };

  getConfig = async (data) => {
    try {
      magicJson.endpoint = "settings";
      magicJson.executor = "getConfig";
      magicJson.data = [data];

      const result = await this.api_request.sendRequest(magicJson);
      return result;
    } catch (error) {
      return error;
    }
  };

  updateGlobalSettings = async (data) => {
    try {
      magicJson.endpoint = "settings";
      magicJson.executor = "updateSettings";
      magicJson.data = [data];
      const result = await this.api_request.sendRequest(magicJson);
      return result;
    } catch (error) {
      return error;
    }
  };

  getGlobalSettings = async () => {
    try {
      magicJson.endpoint = "settings";
      magicJson.executor = "getSettings";
      const result = await this.api_request.sendRequest(magicJson);
      return result;
    } catch (error) {
      return error;
    }
  };

  getMFA = async () => {
    try {
      magicJson.endpoint = "settings";
      magicJson.executor = "getMFA";
      const result = await this.api_request.sendRequest(magicJson);
      return result;
    } catch (error) {
      return error;
    }
  };

  updateMFA = async (data) => {
    try {
      magicJson.endpoint = "settings";
      magicJson.executor = "updateMFA";
      magicJson.data = toRaw(data);
      const result = await this.api_request.sendRequest(magicJson);
      return result;
    } catch (error) {
      return error;
    }
  };
}

export default SettingsService;

<script setup>
import { defineProps, defineEmits, onMounted, ref, watch } from "vue";
import DateTimePicker from "../DateTimePicker.vue";

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    default: "text",
  },
  modelValue: {
    type: [String, Boolean, Array], // Support for checkbox
    default: undefined,
  },
  label: {
    type: String,
    default: "Label comes here",
  },
  required: {
    type: Boolean,
    default: false,
  },
  minLength: {
    type: Number,
    default: null,
  },
  maxLength: {
    type: Number,
    default: null,
  },
  placeholder: {
    type: String,
    default: "Enter here...",
  },
  customComponent: {
    type: Object, // Expect a component
    default: null,
  },
  pattern: {
    type: String,
    default: null,
  },
  options: {
    type: Array,
    default: [],
  },
  isGlobal: {
    type: Boolean,
  },
  defaultValue: {
    type: Array,
  },
  contentExpiration: {
    type: Boolean,
  },
  contentExpirationValue: {
    type: Date,
  },
});
const emit = defineEmits([
  "update:modelValue",
  "update:options",
  "update:contentExpiration",
]);
const showDateModal = ref(false);
const value = ref(props.modelValue);
const datePickerValue = ref(null);
const updateValue = (event) => {
  value.value = event.target.value;

  const updatedOptions = value.value.split("\n").filter((line) => line.trim());
  emit("update:options", updatedOptions);
};

watch(datePickerValue, (newVal) => {
  emit("update:contentExpiration", newVal);
});

const openDateModal = (event) => {
  event.preventDefault();
  showDateModal.value = true;
};

const closeDateModal = async (date) => {
  showDateModal.value = false;
  datePickerValue.value = date;
};

onMounted(() => {
  let str;
  if (props.options.length) str = props?.options;
  else if (props.defaultValue.length) str = props.defaultValue;
  if (str) {
    str = str.join("\n");
    value.value = str;
  } else {
    value.value = "";
  }

  if (props.contentExpirationValue) {
    let mongoDate = new Date(props.contentExpirationValue);
    datePickerValue.value = new Date(mongoDate.toLocaleString());
  }
});
</script>

<template>
  <DateTimePicker
    :showModal="showDateModal"
    :closeModal="closeDateModal"
  ></DateTimePicker>
  <div class="field-preview">
    <label :for="id"
      >{{ label }}
      <span class="required" v-if="required">(Required)</span></label
    >
    <div class="top-right">
      <img
        src="@/assets/unschedule-icon.svg"
        alt="image"
        @click="openDateModal"
        v-if="!datePickerValue && contentExpiration"
      /><img
        src="@/assets/schedule-icon.svg"
        alt="image"
        @click="openDateModal"
        v-if="datePickerValue && contentExpiration"
      />
      <p v-if="datePickerValue">
        {{
          datePickerValue.getDate() +
          "/" +
          datePickerValue.getMonth() +
          "/" +
          datePickerValue.getFullYear() +
          "," +
          datePickerValue.getHours() +
          ":" +
          datePickerValue.getMinutes() +
          ":" +
          datePickerValue.getSeconds()
        }}
      </p>
    </div>

    <!-- textarea(:style="{width: props.width}" class="textAreaInput" :placeholder="props.placeholder" :value="value" :rows="5" @input="updateValue" :required="props.required") -->
    <textarea
      class="textAreaInput"
      :id="id"
      @input="updateValue"
      :value="value"
      :disabled="isGlobal"
      :placeholder="placeholder"
      :required="required"
    ></textarea>
  </div>
</template>

<style lang="scss" scoped>
.field-preview {
  width: 98%;
  display: flex;
  flex-direction: column;
  gap: 3px;

  label {
    font-weight: bold;

    .required {
      color: #606060;
      font-weight: 300;
      font-size: smaller;
      margin-left: 5px;
    }
  }

  input,
  textarea {
    width: auto !important;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    outline: none;
    color: black;

    padding: 10px;
  }

  input::placeholder,
  textarea::placeholder {
    color: #999;
  }

  .char-info {
    font-size: 12px;
    color: #666;
    display: flex;
    justify-content: space-between;
  }
}

.top-right {
  display: flex;
  gap: 0.5rem;
}
</style>

import api_request from "./../../../libs/sendRequest/sendRequest";
import magicJson from "./../../../magicJson";

class authService {
  apiRequest = new api_request("/api/service");

  login = async (data) => {
    try {
      magicJson.endpoint = "auth";
      magicJson.executor = "login";
      magicJson.data = [data];
      const result = await this.apiRequest.sendRequest(magicJson, null, false);
      return result;
    } catch (error) {
      return error;
    }
  };
  oAuthLogin = async (data) => {
    try {
      magicJson.endpoint = "auth";
      magicJson.executor = "oAuthLogin";
      magicJson.data = [data];
      const result = await this.apiRequest.sendRequest(magicJson, null, false);
      return result;
    } catch (error) {
      return error;
    }
  };
  register = async (data, image) => {
    try {
      magicJson.endpoint = "auth";
      magicJson.executor = "register";
      magicJson.data = [data];
      const result = await this.apiRequest.sendRequest(magicJson, image, false);
      return result;
    } catch (error) {
      return error;
    }
  };
  getAllUser = async (data) => {
    try {
      magicJson.endpoint = "auth";
      magicJson.executor = "getAllUser";
      magicJson.data = [data];
      const result = await this.apiRequest.sendRequest(magicJson, null, false);
      return result;
    } catch (error) {
      return error;
    }
  };
  getUserByEmail = async (data) => {
    try {
      magicJson.endpoint = "auth";
      magicJson.executor = "getUserByEmail";
      magicJson.data = [data];
      const result = await this.apiRequest.sendRequest(magicJson, null, false);
      return result;
    } catch (error) {
      return error;
    }
  };
  getUserById = async (data) => {
    try {
      magicJson.endpoint = "auth";
      magicJson.executor = "getUserById";
      magicJson.data = [data];
      const result = await this.apiRequest.sendRequest(magicJson, null, false);
      return result;
    } catch (error) {
      return error;
    }
  };
  UpdateUser = async (data, image, userId) => {
    try {
      magicJson.endpoint = "auth";
      magicJson.executor = "UpdateUser";
      magicJson.data = [{ data, id: userId }];
      const result = await this.apiRequest.sendRequest(magicJson, image, false);
      return result;
    } catch (error) {
      return error;
    }
  };
  setStatusToDeleted = async (data) => {
    try {
      magicJson.endpoint = "auth";
      magicJson.executor = "setStatusToDeleted";
      magicJson.data = [data];
      const result = await this.apiRequest.sendRequest(magicJson, false);
      return result;
    } catch (error) {
      return error;
    }
  };
  updateMultipleUsers = async (data, userIds) => {
    try {
      magicJson.endpoint = "auth";
      magicJson.executor = "updateMultipleUsers";
      magicJson.data = [{ data, arr: userIds }];
      const result = await this.apiRequest.sendRequest(magicJson, false);
      return result;
    } catch (error) {
      return error;
    }
  };
  forgotPassword = async (userEmail) => {
    try {
      magicJson.endpoint = "auth";
      magicJson.executor = "forgotPassword";
      magicJson.data = [{ userEmail }];
      const result = await this.apiRequest.sendRequest(magicJson, false);
      return result;
    } catch (error) {
      return error;
    }
  };
  resetPassword = async (data) => {
    try {
      magicJson.endpoint = "auth";
      (magicJson.executor = "resetPassword"), (magicJson.data = [data]);
      const result = await this.apiRequest.sendRequest(magicJson, false);
      return result;
    } catch (error) {
      return error;
    }
  };
  verifyOtp = async (userEmail, otp) => {
    try {
      magicJson.endpoint = "auth";
      magicJson.executor = "verifyOTP";
      magicJson.data = [{ userEmail, userOTP: otp }];
      const result = await this.apiRequest.sendRequest(magicJson, false);
      return result;
    } catch (error) {
      return error;
    }
  };
  generateUniqueMfaKey = async (userEmail) => {
    try {
      magicJson.endpoint = "auth";
      magicJson.executor = "generateUniqueMfaKey";
      magicJson.data = [{ userEmail }];
      const result = await this.apiRequest.sendRequest(magicJson, false);
      return result;
    } catch (error) {
      return error;
    }
  };
  resetMFA = async (userId) => {
    try {
      magicJson.endpoint = "auth";
      magicJson.executor = "resetMFA";
      magicJson.data = [{ userId }];
      const result = await this.apiRequest.sendRequest(magicJson, false);
      return result;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
}

export default authService;

<script setup>
import {
  defineProps,
  ref,
  toRaw,
  watch,
  nextTick,
  computed,
  onMounted,
} from "vue";
import { useStore } from "vuex";
import { defineExpose } from "vue";
import InputComponent from "./InputComponent.vue";
import SelectComponent from "./SelectComponent.vue";
import TextAreaComponent from "./TextAreaComponent.vue";
import { v4 as uuidv4 } from "uuid";
import { useToast } from "vue-toast-notification";
import DateTimePicker from "../DateTimePicker.vue";

const store = useStore();
const items = computed(() => store.getters.items);
const emit = defineEmits(["update:contentExpiration"]);
const datePickerValue = ref(null);
const showDateModal = ref(false);
// Define props
const props = defineProps({
  form: {
    type: Object,
    required: true,
  },
});

watch(datePickerValue, (newVal) => {
  emit("update:contentExpiration", newVal);
});

const openDateModal = (event) => {
  event.preventDefault();
  showDateModal.value = true;
};

const closeDateModal = async (date) => {
  showDateModal.value = false;
  datePickerValue.value = date;
};

const toast = useToast();
const images = ref([]);

// Track whether this accordion is open or closed
const isAccordionOpen = ref(false);

const toggleAccordion = () => {
  isAccordionOpen.value = !isAccordionOpen.value;
};

const getFormField = (field) => {
  let fileName = null;
  if (
    field.componentName === "Single Media" ||
    field.componentName === "Multiple Media"
  ) {
    if (field.value?.length) {
      let result = images.value.reduce((acc, [id, obj]) => {
        if (!acc[id]) {
          acc[id] = 0;
        }

        if (field?.componentName === "Single Media") {
          acc[id] = 1;
        } else {
          if (obj) {
            acc[id] += Object.keys(obj).length;
          }
        }

        return acc;
      }, {});

      // Ensure result[field.id] exists and fallback to 0 if undefined
      const fieldResult = result[field.id] || 0;

      if (field.componentName === "Multiple Media") {
        if (field.files?.length) {
          fileName = `${field.files.length + fieldResult} files`;
        } else {
          fileName = `${fieldResult} files`;
        }
      } else if (field.componentName === "Single Media") {
        if (fieldResult) {
          // Logic to use field ID to get the correct name
          const currentFieldIndex = images.value.findLastIndex(
            ([currentId]) => currentId === field.id
          );

          fileName = images.value[currentFieldIndex][1][0].name;
        } else {
          fileName = field.files[0].name;
        }
      }
    }
  }
  const baseProps = {
    id: field?.id,
    label: field?.label,
    required: field?.isRequired,
    modelValue: field?.value,
    minLength: field?.minimumLength,
    maxLength: field?.maximumLength,
    minValue: field?.minimumValue,
    maxValue: field?.maximumValue,
    defaultValue: field?.defaultValue,
    placeholder: field?.placeholder,
    pattern: field?.regExp,
    options: field?.options,
    allowMultiple: field?.allowMultiple,
    showComponentItself: field?.showComponentItself,
    fileName: fileName,
    format: field?.format,
    isGlobal: props.form.isGlobal,
  };

  switch (field.componentName) {
    case "Text":
    case "Short Text":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "text",
        },
      };
    case "Long Text":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "textarea",
        },
      };
    case "Email":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "email",
        },
      };
    case "Password":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "password",
        },
      };
    case "Number":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "number",
        },
      };
    case "Rich Text":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "richtext",
        },
      };
    case "Telephone":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "tel",
        },
      };
    case "Radio":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "radio",
        },
      };
    case "Range":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "range",
        },
      };
    case "Checkbox":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "checkbox",
        },
      };
    case "Date":
      if (field.format === "time") {
        return {
          component: InputComponent,
          props: {
            ...baseProps,
            type: "time",
          },
        };
      } else if (field.format === "date") {
        return {
          component: InputComponent,
          props: {
            ...baseProps,
            type: "date",
          },
        };
      } else if (field.format === "month") {
        return {
          component: InputComponent,
          props: {
            ...baseProps,
            type: "month",
          },
        };
      }
    case "Datetime":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "datetime-local",
        },
      };
    case "Week":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "week",
        },
      };
    case "Month":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "month",
        },
      };
    case "Time":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "time",
        },
      };
    case "Link":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "url",
        },
      };
    case "Single Media":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "file",
        },
      };
    case "Boolean":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "checkbox",
        },
      };
    case "Multiple Media":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "file",
          multiple2: true,
        },
      };

    case "Image":
      return {
        component: InputComponent,
        props: {
          ...baseProps,
          type: "image",
        },
      };
    case "Enumeration":
      return {
        component: SelectComponent,
        props: {
          ...baseProps,
          options: field.options || [],
        },
      };
    case "Lists":
      return {
        component: TextAreaComponent,
        props: {
          ...baseProps,
          options: field.options || [],
        },
      };
    case "CheckBox":
      return {
        component: TextAreaComponent,
        props: {
          ...baseProps,
          options: field.options || [],
        },
      };
    case "RadioButton":
      return {
        component: TextAreaComponent,
        props: {
          ...baseProps,
          options: field.options || [],
        },
      };
    case "Array":
      return {
        component: TextAreaComponent,
        props: {
          ...baseProps,
          options: field.options || [],
        },
      };
    case "JSON":
      return {
        component: TextAreaComponent,
        props: {
          ...baseProps,
          options: field.options || [],
        },
      };
    default:
      return null;
  }
};

const returnImagesData = async () => {
  let compCount = await countComponents(toRaw(props.form.childComponents));
  let data = toRaw(images.value);
  await nextTick();
  let imageData = [];

  if (compCount) {
    for (let i = 0; i < compCount; i++) {
      let imageDt = await ImagesRef.value[i].returnImagesData();
      imageData.push(imageDt);
    }
  }
  data = [...data, ...imageData.flat()];

  return data;
};

const replicateComponent = async (e) => {
  e.preventDefault();
  let oldData = items.value[0];
  let data = structuredClone(props.form);
  let currentId = data.id;
  data.replicated = true;
  data.childComponents = data.childComponents;
  let newData = await addSiblingComponent(
    oldData?.componentData,
    currentId,
    await updateValuesToEmpty(data)
  );
  newData = await updateComponentIds(newData);
  oldData.componentData = newData;
  store.dispatch("updateItem", { index: 0, newItem: oldData });
};

// Function to clear all "value" fields in the nested structure
async function updateValuesToEmpty(component) {
  // Check if the current component has a 'value' field and set it to an empty string
  if (component.hasOwnProperty("value")) {
    component.value = null;
  }

  // If the component has 'childComponents', recursively call this function for each child
  if (
    component.hasOwnProperty("childComponents") &&
    Array.isArray(component.childComponents)
  ) {
    component.childComponents.forEach((child) => updateValuesToEmpty(child));
  }

  return component;
}

async function addSiblingComponent(data, targetId, newObject) {
  // Deep clone the original data to avoid modifying it
  const clonedData = JSON.parse(JSON.stringify(data));

  let found = false; // Flag to track if the target component was found

  function recursiveAddSibling(components) {
    for (let i = 0; i < components.length; i++) {
      const component = components[i];

      // Check the current component
      if (component.id === targetId) {
        // Insert the newObject as a sibling at the same level
        components.splice(i + 1, 0, newObject);
        found = true; // Mark the target as found
        return true;
      }

      // Recursively check childComponents if present
      if (Array.isArray(component.childComponents)) {
        const added = recursiveAddSibling(component.childComponents);
        if (added) return true;
      }
    }
    return false;
  }

  // Perform the recursive search and insertion on the cloned data
  recursiveAddSibling(clonedData);

  // If the targetId is in the top-level array, handle it explicitly
  if (!found) {
    for (let i = 0; i < clonedData.length; i++) {
      if (clonedData[i].id === targetId) {
        clonedData.splice(i + 1, 0, newObject);
        found = true;
        break;
      }
    }
  }

  return clonedData;
}

const updateComponentIds = async (components) => {
  // Helper function to recursively update IDs
  function updateComponentIds(component) {
    // Assign new ID (you can define how to generate or assign it)
    component.id = uuidv4();

    // Check if there are nested child components
    if (component.childComponents && component.childComponents.length > 0) {
      component.childComponents.forEach(updateComponentIds); // Recursive call
    }
  }

  // Iterate over the root components and update their IDs
  components.forEach(updateComponentIds);

  return components;
};

const deleteComponentById = async (id) => {
  if (
    await swal({ title: "Continue to delete?", icon: "warning", buttons: true })
  ) {
    try {
      await deleteComponent(id);
      toast.success("Component deleted.");
    } catch {
      toast.error("Something went wrong.");
    }
  }
};

const deleteComponent = async (id) => {
  let obj = items.value[0];
  // Function to recursively search and delete by id
  const recursiveDelete = (components) => {
    return components.filter((component) => {
      // Check if the component has childComponents and recursively search
      if (component.childComponents) {
        component.childComponents = recursiveDelete(component.childComponents);
      }
      // Return false if the id matches, to filter out the object
      return component.id !== id;
    });
  };

  // Start the deletion from componentData array
  obj.componentData = recursiveDelete(obj.componentData);
  return obj;
};

const ImagesRef = ref(null);

async function countComponents(componentData) {
  let componentCount = 0;

  // Loop through the componentData array
  componentData.forEach((component) => {
    // Check if the current component has componentName === "Components"
    if (component.componentName === "Components" && !component.isGlobal) {
      componentCount++; // Count the top-level component

      // If the component has childComponents, iterate through them and count "Components"
      // if (Array.isArray(component.childComponents)) {
      //   component.childComponents.forEach(child => {
      //     if (child.componentName === "Components" && !component.isGlobal) {
      //       componentCount++;
      //     }
      //   });
      // }
    }
  });

  return componentCount;
}

const arrangeComponents = async (id, direction) => {
  let result = await moveComponent(items.value[0].componentData, id, direction);
};

async function moveComponent(data, targetId, direction) {
  if (!["up", "down"].includes(direction)) {
    throw new Error("Direction must be 'up' or 'down'");
  }

  // Helper function to find and move the component within a given array
  function moveInArray(array, index, direction) {
    if (direction === "up" && index > 1) {
      // Prevent moving above index 1
      [array[index - 1], array[index]] = [array[index], array[index - 1]];
    } else if (direction === "down" && index < array.length - 1) {
      [array[index], array[index + 1]] = [array[index + 1], array[index]];
    }
  }

  // Recursive function to traverse and handle nested components
  function traverseAndMove(components) {
    for (let i = 0; i < components.length; i++) {
      const component = components[i];

      // Check if the current component matches the target ID
      if (component.id === targetId) {
        moveInArray(components, i, direction);
        return true; // Stop further traversal once moved
      }

      // If child components exist, traverse them
      if (component.childComponents && component.childComponents.length > 0) {
        if (traverseAndMove(component.childComponents)) {
          return true;
        }
      }
    }
    return false; // Target not found in this branch
  }

  // Start traversal from the root level
  traverseAndMove(data);
  return data;
}

onMounted(() => {
  if (props.form.contentExpirationValue) {
    let mongoDate = new Date(props.form.contentExpirationValue);
    datePickerValue.value = new Date(mongoDate.toLocaleString());
  }
});

defineExpose({ returnImagesData });
</script>

<template>
  <DateTimePicker
    :showModal="showDateModal"
    :closeModal="closeDateModal"
  ></DateTimePicker>
  <div class="accordion" :id="`accordion-${props.form.id}`">
    <div class="accordion-item">
      <h2 class="accordion-header">
        <div class="action-buttons">
          <div
            class="accordion-button"
            type="button"
            :class="{ collapsed: !isAccordionOpen }"
            @click="toggleAccordion"
            :aria-expanded="isAccordionOpen"
            :aria-controls="`collapse-${props.form.id}`"
          >
            <b>{{
              props.form.isGlobal
                ? "Global Component : " + props.form.label
                : props.form.label
            }}</b>
            <div class="top-right">
              <img
                src="@/assets/unschedule-icon.svg"
                alt="image"
                @click="openDateModal"
                v-if="!form.datePickerValue && form.contentExpiration"
              /><img
                src="@/assets/schedule-icon.svg"
                alt="image"
                @click="openDateModal"
                v-if="form.datePickerValue && form.contentExpiration"
              />
              <p v-if="datePickerValue">
                {{
                  datePickerValue.getDate() +
                  "/" +
                  datePickerValue.getMonth() +
                  "/" +
                  datePickerValue.getFullYear() +
                  "," +
                  datePickerValue.getHours() +
                  ":" +
                  datePickerValue.getMinutes() +
                  ":" +
                  datePickerValue.getSeconds()
                }}
              </p>
            </div>
          </div>
          <v-icon
            class="icon-style"
            name="bi-arrow-up"
            @click="arrangeComponents(form.id, 'up')"
            v-if="form.replicated"
          />
          <v-icon
            name="bi-arrow-down"
            @click="arrangeComponents(form.id, 'down')"
            v-if="form.replicated"
          />
          <v-icon
            class="trashIcon icon-style"
            @click="deleteComponentById(form.id)"
            name="fa-trash-alt"
            v-if="form.replicated"
          />
        </div>
      </h2>
      <div
        :id="`collapse-${props.form.id}`"
        class="accordion-collapse collapse"
        :class="{ show: isAccordionOpen }"
        v-if="props.form.childComponents && props.form.childComponents.length"
      >
        <div class="accordion-body">
          <div class="colorclass">
            <template
              v-for="(item, index) in props.form.childComponents"
              :key="index"
            >
              <template v-if="item.componentName !== 'Components'">
                <component
                  v-if="item.componentName !== 'Collections'"
                  :is="getFormField(item)?.component"
                  v-bind="getFormField(item)?.props"
                  @update:modelValue="
                    (value) => {
                      if (getFormField(item)?.props.type === 'file') {
                        item.value = value;
                        images.push([item.id, value]);
                      } else {
                        item.value = value;
                      }
                    }
                  "
                  @update:options="(value) => (item.options = value)"
                />
                <div
                  v-else,
                  class="collection_item"
                  title="You can add collection items from Content Manager > Collections"
                >
                  <label>
                    <p>Collection : {{ item.label }}</p>
                  </label>
                </div>
              </template>
              <template v-else>
                <ContentManagerComponent
                  :form="{ isGlobal: props.form.isGlobal, ...item }"
                  ref="ImagesRef"
                />
              </template>
            </template>
            <!-- <component
              v-for="(field, index) in props.form.childComponents"
              :key="index"
              :is="getFormField(field)?.component"
              v-bind="getFormField(field)?.props"
              @update:modelValue="(value) => (field.value = value)"
              @update:options="(value) => (field.options = value)"
            /> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="replicate-div">
    <img
      src="@/assets/repeatable-comp-icon.svg"
      alt="image"
      @click="replicateComponent"
      v-if="!form.replicated && form.multiple"
      class="replicate-btn"
    />
  </div>
</template>

<style scoped>
.accordion-item {
  margin: 2px;
}

.labelstyle {
  margin: 2vh 0vh 0vh 0vh;
}

.accordion-button {
  width: 100%;
  color: #0146f4 !important;
  margin: 0;
}

.accordion-button:focus {
  box-shadow: none;
}

/* .accordion-button:not(.collapsed) {
  background-color: white;
} */

.inputstyle {
  width: 100%;
}

.colorclass {
  color: #0146f4;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.borderedContainer2 {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  color: #0146f4;
}

.form-preview-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 0rem;
}

input {
  width: auto !important;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  color: black;
  padding: 10px;
}

input::placeholder {
  color: #999;
}

.accordion {
  width: 98%;
  z-index: 1;
}

.collection_item {
  width: 98%;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  padding: 16px 20px;
  cursor: pointer;

  p {
    font-size: 1rem;
  }
}

.replicate-div {
  display: flex;
  justify-content: center;
}

.replicate-btn {
  margin-top: -2.5rem;
  position: relative;
  cursor: pointer;
  z-index: 5;
}

.trashIcon {
  color: red;
}

.action-buttons {
  display: flex;
  align-items: center;
  padding-right: 1rem;
}

.top-right {
  display: flex;
  gap: 0.5rem;
  position: absolute;
  right: 0;
  margin-right: 3rem;
}
</style>

<script setup>
import { onMounted, ref, watch } from "vue";
import { VDatePicker } from "vuetify/components";
import { VTimePicker } from "vuetify/labs/VTimePicker";

const props = defineProps({
  showModal: {
    type: Boolean,
    default: false,
  },
  closeModal: {
    type: Function,
  },
});

const showDateModal = ref(true);
const dateBool = ref(true);
const timeBool = ref(false);
const dateValue = ref(new Date());
const timeValue = ref("00:00");
const daysOfWeekShort = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const monthsShort = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const timeFormat = ref("am");
const datePickerRef = ref(null);
const timePickerRef = ref(null);
const timeKey = ref(1);

const openDateModal = (event) => {
  event.preventDefault();
  showDateModal.value = true;
};
const toggleDateTime = async (type) => {
  if (type === "date") {
    dateBool.value = true;
    timeBool.value = false;
  } else {
    dateBool.value = false;
    timeBool.value = true;
  }
};
const changeFormat = async (format) => {
  timeFormat.value = format;
};
const resetDateTime = async () => {
  if (dateBool.value) dateValue.value = new Date();
  else {
    timeValue.value = "00:00";
    timeKey.value++;
  }
};
const closeDateModal = async () => {
  showDateModal.value = false;
};

const createDateTime = async () => {
  let [hours, minutes] = timeValue.value.split(":").map(Number);

  if (timeFormat.value === "pm" && hours !== 12) {
    hours += 12;
  }
  if (timeFormat.value === "am" && hours === 12) {
    hours = 0;
  }

  dateValue.value.setHours(hours, minutes, 0);

  props.closeModal(dateValue.value);
};

const handleTimeChange = async (time) => {
  const [hours, minutes] = timeValue.value.split(':');
  if(time < 10) {
    timeValue.value = "0" + time + ":" + minutes;
  }
  else
    timeValue.value = time + ":" + minutes;
}

onMounted(() => {
  dateValue.value = new Date();
});
</script>

<template>
  <div class="modal-backdrop" v-if="showModal">
    <div class="modal-content">
      <div class="display-section">
        <div class="date-display" v-if="dateBool">
          <p class="select-heading">SELECT DATE</p>
          <div class="date-display-content">
            <p class="show-date">
              {{ daysOfWeekShort[dateValue.getDay()] }},
              {{ monthsShort[dateValue.getMonth()] }} {{ dateValue.getDate() }}
            </p>
          </div>
        </div>
        <div class="time-display" v-if="timeBool">
          <p class="show-time">{{ timeValue }}</p>
          <div class="format-section">
            <p
              @click="changeFormat('am')"
              :class="{ 'selected-format': timeFormat === 'am' }"
            >
              AM
            </p>
            <p
              @click="changeFormat('pm')"
              :class="{ 'selected-format': timeFormat === 'pm' }"
            >
              PM
            </p>
          </div>
        </div>
      </div>
      <div class="date-time-selector">
        <div
          class="date-div"
          @click="toggleDateTime('date')"
          :class="{ 'date-time-selected': dateBool }"
        >
          <img src="@/assets/date-picker.svg" />
        </div>
        <div
          class="time-div"
          @click="toggleDateTime('time')"
          :class="{ 'date-time-selected': timeBool }"
        >
          <img src="@/assets/time-picker.svg" />
        </div>
      </div>
      <div class="date-time-content">
        <v-date-picker
          hide-header="hide-header"
          color="primary"
          v-model="dateValue"
          v-if="dateBool"
          ref="datePickerRef"
        ></v-date-picker>
        <v-time-picker
          hide-header="hide-header"
          v-model="timeValue"
          v-if="timeBool"
          :key="timeKey"
          @update:hour="handleTimeChange"
          ref="timePickerRef"
        ></v-time-picker>
        <div class="button-section">
          <p class="clear-button" @click="resetDateTime">CLEAR</p>
          <div class="right-button">
            <p class="clear-button" @click="closeModal(null)">CANCEL</p>
            <p class="clear-button" @click="createDateTime">OK</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.top-section {
  display: flex;
  justify-content: space-between;
}
.time-display {
  display: flex;
  justify-content: center;
  align-items: center;
  .show-time {
    font-size: 3em;
  }
  .format-section {
    position: absolute;
    right: 0;
    margin-right: 3rem;
    p {
      cursor: pointer;
    }
  }
}
.selected-format {
  font-weight: 1000;
}
.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
}
.date-display {
  padding: 1rem;
  .select-heading {
    font-weight: 500;
    font-size: small;
  }
  .show-date {
    font-size: xx-large;
    font-weight: 400;
  }
}
.date-time-selector {
  display: flex;
  width: 100%;
}
.date-div,
.time-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  background-color: rgba(1, 70, 244, 1);
  padding: 0.5rem;
  cursor: pointer;
}
.display-section {
  background-color: rgba(1, 70, 244, 1);
  color: white;
  width: 100%;
}
.date-time-selected {
  background-color: #0134b7;
}
.button-section {
  background-color: white;
  display: flex;
  justify-content: space-between;
  color: blue;
  font-weight: 500;
  font-size: small;
  padding: 1rem;
  margin-top: -1rem;
}
.clear-button {
  cursor: pointer;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px;
}
.right-button {
  display: flex;
  gap: 1rem;
}
</style>

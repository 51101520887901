<script setup>
import { ref, defineExpose, onMounted, computed, toRaw } from "vue";
import { useStore } from "vuex";

// Define props
const props = defineProps({
  id: {
    type: String,
  },
  label: {
    type: String,
    required: true,
    default: "Name",
  },
  type: {
    type: String,
    default: "text",
  },
  value: {
    type: String,
    default: "",
  },
  width: {
    type: String,
  },
  placeholder: {
    type: String,
    default: "",
  },
  rule: {
    type: String,
    default: "",
  },
  data: {
    type: Object,
    default: {},
  },
  required: {
    type: Boolean,
    default: false,
  },
});
const store = useStore();
let dateType = computed(() => store.getters.dateType);

// Reactive value based on prop
const value = ref(props.data.label || "");

// Method to update the reactive value
const updateValue = (event) => {
  value.value = event.target.value;
};

function resolveValue() {
  if (props.data) {
    if (props.label == "Name") value.value = props.data.label;

    if (props.label == "Default Value") value.value = props.data.defaultValue;

    if (props.label == "RegExp Pattern") value.value = props.data.regExp;

    if (props.label == "Placeholder") value.value = props.data.placeholder;
  }
}

// Expose getData method
defineExpose({
  getData: () => {
    return { value: value.value };
  },
});

onMounted(() => {
  resolveValue();
});
</script>

<template>
  <div class="formComponentBody">
    <div class="formComponent_Title">{{ props.label }}</div>
    <div class="formInputFieldBody">
      <input
        :style="{ width: props.width }"
        class="formInput"
        :placeholder="props.placeholder"
        :value="value"
        :type="props.type === 'date' ? dateType : props.type"
        @input="updateValue"
        :required="props.required"
        :step="props.id === 'input1' ? 'any' : undefined "
      />
    </div>

    <p class="rule" v-if="props.rule">
      {{ props.rule }}
    </p>
  </div>
</template>

import { createRouter, createWebHistory } from "vue-router";

// Auth
import HcmsLogin from "./../views/login/HcmsLogin.vue";

// AdminDashboard
import AdminDashboard from "../views/AdminDashboard";
// Jobs
import JobsAnalytics from "./../views/adminJobs/JobsAnalytics";
import JobBoards from "./../views/adminJobs/JobBoards";
import CiepalJobs from "./../views/adminJobs/CiepalJobs";
import CreateNewJobs from "./../views/adminJobs/CreateNewJobs";
import UpdateJobs from "./../views/adminJobs/UpdateJobs";
import JobPreview from "./../views/adminJobs/JobPreview";
import Candidates from "./../views/adminJobs/Candidates";
import CandidateProfile from "./../views/adminJobs/CandidateProfile";
import CompanyProfile from "./../views/adminJobs/CompanyProfile";
// Campaigns
// import SubscribersList from "./../views/adminCampaigns/SubscribersList";
// import Emails from "./../views/adminCampaigns/Emails";
// import CampaignsAnalytics from "./../views/adminCampaigns/CampaignsAnalytics";
// import CreateCampaigns from "./../views/adminCampaigns/CreateCampaigns";
// Teams
import Team from "./../views/adminCompany/Team.vue";
import CreateNewMember from "./../views/adminCompany/CreateNewMember.vue";
import UpdateMember from "././../views/adminCompany/UpdateMember.vue";
// Forms
import FormAnalytics from "./../views/adminForms/FormAnalytics";
import SubmittedList from "./../views/adminForms/SubmittedList";
import Views from "./../views/adminForms/Views";
import Subscribers from "./../views/adminForms/Subscribers.vue";
import ContactUsResponses from "./../views/adminForms/ContactUsResponses.vue";

import CreateForm from "./../views/adminForms/CreateForms";
import ViewForm from "./../views/adminForms/ViewForm";
// Resources
import CreateBlog from "./../views/resources/CreateBlog.vue";
import ViewBlogs from "./../views/resources/ViewBlogs.vue";
import UpdateBlog from "./../views/resources/UpdateBlog.vue";
import Categories from "./../views/resources/Categories.vue";
import PreviewPage from "./../views/resources/PreviewPage.vue";
// Users
import CreateUser from "./../views/users/CreateUser.vue";
import UpdateUser from "./../views/users/UpdateUser.vue";
import CreateUserManually from "./../views/users/CreateUserManually.vue";
import ManageUser from "./../views/users/ManageUser.vue";
// Access
import createRoleTemplate from "./../views/accessControl/createRoleTemplate.vue";
import accessControlMatrix from "./../views/accessControl/accessControlMatrix.vue";

// Settings
import Configurations from "./../views/adminSettings/Configurations.vue";
import AddConfiguration from "./../views/adminSettings/AddConfiguration.vue";
import MfaSettings from "./../views/adminSettings/MfaSettings.vue";

//MediaLibrary
import mediaLibrary from "@/views/mediaLibrary/MediaLibrary.vue";

// Not Found
import PageNotFound from "./../views/PageNotFound.vue";

// Content Builder
import Pages from "@/views/contentTypeBuilder/Pages.vue";
import ComponentsBuilder from "@/views/contentTypeBuilder/ComponentsBuilder.vue";
import SingleComponent from "@/views/contentTypeBuilder/SingleComponents.vue";
import LandingPage from "@/views/contentTypeBuilder/LandingPage.vue";

// Content Manager
import PageManager from "@/views/contentManager/PageManager.vue";
import Collections from "@/views/contentManager/Collections.vue";
import ComponentsManager from "@/views/contentManager/ComponentsManager.vue";
import CollectionsManager from "@/views/contentManager/CollectionsManager.vue";
import LandingPageCollections from "@/views/contentTypeBuilder/LandingPageCollections.vue";

const routes = [
  {
    path: "/login",
    name: "HcmsLogin",
    component: HcmsLogin,
  },

  // forgot password
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: HcmsLogin,
    meta: { requiresAuth: false },
  },

  // reset password
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: HcmsLogin,
    meta: { requiresAuth: false },
  },

  // multifactor auth
  {
    path: "/mfa",
    name: "MultiFactorAuth",
    component: HcmsLogin,
    meta: { requiresAuth: false },
  },

  // AdminDashboard
  {
    path: "/dashboard",
    name: "AdminDashboard",
    component: AdminDashboard,
    meta: { requiresAuth: true },
  },

  // Jobs
  {
    path: "/jobs",
    name: "Jobs",
    meta: { requiresAuth: true },
    children: [
      {
        path: "job-boards",
        name: "JobBoards",
        component: JobBoards,
      },
      {
        path: "ciepal-jobs",
        name: "CiepalJobs",
        component: CiepalJobs,
      },
      {
        path: "candidates/:id",
        name: "Candidates",
        component: Candidates,
      },
      {
        path: "candidate-profile/:id",
        name: "CandidateProfile",
        component: CandidateProfile,
      },
      {
        path: "create-new-jobs",
        name: "CreateNewJobs",
        component: CreateNewJobs,
      },
      {
        path: "update-jobs/:id",
        name: "UpdateJobs",
        component: UpdateJobs,
      },
      {
        path: "preview-jobs/:id",
        name: "JobPreview",
        component: JobPreview,
      },
      {
        path: "analytics",
        name: "JobsAnalytics",
        component: JobsAnalytics,
      },
      {
        path: "company-profile",
        name: "CompanyProfile",
        component: CompanyProfile,
      },
    ],
  },
  // Campaigns
  // {
  //   path: "/campaigns",
  //   name: "Campaigns",
  //   // component: SubscribersList,
  //   children: [
  //     {
  //       path: "subscribersList",
  //       name: "SubscribersList",
  //       component: SubscribersList,
  //     },
  //     {
  //       path: "createCampaigns",
  //       name: "CreateCampaigns",
  //       component: CreateCampaigns,
  //     },
  //     {
  //       path: "emails",
  //       name: "Emails",
  //       component: Emails,
  //     },
  //     {
  //       path: "analytics",
  //       name: "CampaignsAnalytics",
  //       component: CampaignsAnalytics,
  //     },
  //   ],
  // },
  // Access
  {
    path: "/access",
    name: "Access",
    meta: { requiresAuth: true },
    children: [
      {
        path: "create-role-template",
        name: "createRoleTemplate",
        component: createRoleTemplate,
      },
      {
        path: "access-control-matrix",
        name: "accessControlMatrix",
        component: accessControlMatrix,
      },
    ],
  },
  // Users
  {
    path: "/users",
    name: "Users",
    meta: { requiresAuth: true },
    children: [
      {
        path: "create-user",
        name: "createUser",
        component: CreateUser,
      },
      {
        path: "update-user/:id",
        name: "updateUser",
        component: UpdateUser,
      },
      {
        path: "create-user/create-user-manually",
        name: "createUserManually",
        component: CreateUserManually,
      },
      {
        path: "manage-user",
        name: "manageUser",
        component: ManageUser,
      },
    ],
  },
  {
    path: "/content-type-builder",
    name: "Content Type Builder",
    meta: { requiresAuth: true },
    children: [
      {
        path: "pages",
        name: "Pages",
        component: Pages,
      },
      {
        path: "components",
        name: "ComponentsBuilder",
        component: ComponentsBuilder,
      },
      {
        path: "pages/landing-page/:componentName",
        name: "SingleComponentForLandingPage",
        component: SingleComponent,
      },
      {
        path: "pages/collection/:componentName",
        name: "SingleComponentForCollection",
        component: SingleComponent,
      },
      {
        path: "components/:componentName",
        name: "SingleComponent",
        component: SingleComponent,
      },
      {
        path: "components/landingPage",
        name: "LandingPage",
        component: LandingPage,
      },
    ],
  },
  {
    path: "/content-manager",
    name: "Content Manager",
    meta: { requiresAuth: true },
    children: [
      {
        path: "pages",
        name: "PageManager",
        component: PageManager,
      },
      {
        path: "collections",
        name: "Collections",
        component: Collections,
      },
      {
        path: "components",
        name: "ComponentsManager",
        component: ComponentsManager,
      },
      {
        path: "pages/:landingPage",
        name: "SinglePageManager",
        component: LandingPage,
      },
      {
        path: "components/:componentName",
        name: "SingleComponentManager",
        component: LandingPage,
      },
      {
        path: "collections/:collectionName",
        name: "CollectionsManager",
        component: CollectionsManager,
      },
      {
        path: "collections/:collectionName/collection-item",
        name: "LandingPageCollection",
        component: LandingPageCollections,
      },
    ],
  },
  // Forms
  {
    path: "/forms",
    name: "Forms",
    meta: { requiresAuth: true },
    children: [
      {
        path: "create-form",
        name: "CreateForm",
        component: CreateForm,
        props: true,
      },
      {
        path: "submitted-list",
        name: "SubmittedList",
        component: SubmittedList,
      },
      {
        path: "views",
        name: "Views",
        component: Views,
      },
      {
        path: "view-form",
        name: "ViewForm",
        component: ViewForm,
      },
      {
        path: "analytics",
        name: "FormAnalytics",
        component: FormAnalytics,
      },
      {
        path: "subscribers",
        name: "Subscribers",
        component: Subscribers,
      },
      {
        path: "contact-us-responses",
        name: "ContactUsResponses",
        component: ContactUsResponses,
      },
    ],
  },
  {
    path: "/media-library",
    name: "Media Library",
    meta: { requiresAuth: true },
    children: [
      {
        path: "lib",
        name: "Gallery",
        component: mediaLibrary,
      },
      {
        path: "lib/:id",
        name: "Subfolder",
        component: mediaLibrary,
      },
    ],
  },
  // Resources
  {
    path: "/resources",
    name: "Resources",
    meta: { requiresAuth: true },
    children: [
      {
        path: "create-blog",
        name: "CreateBlog",
        component: CreateBlog,
      },
      {
        path: "view-blogs",
        name: "ViewBlogs",
        component: ViewBlogs,
      },
      {
        path: "view-blogs/:blogId/update",
        name: "UpdateBlog",
        component: UpdateBlog,
      },
      {
        path: "view-blogs/:blogId/preview",
        name: "PreviewPage",
        component: PreviewPage,
      },
      {
        path: "categories",
        name: "Categories",
        component: Categories,
      },
      {
        path: "categories/:parentCategory?/:category?/view-blogs",
        name: "viewBlogsByCategory",
        component: ViewBlogs,
      },
    ],
  },
  // Company
  {
    path: "/company",
    name: "Company",
    // component: Team,
    meta: { requiresAuth: true },
    children: [
      {
        path: "team",
        name: "Team",
        component: Team,
      },
      {
        path: "create-new-member",
        name: "CreateNewMember",
        component: CreateNewMember,
      },
      {
        path: "update-member/:id",
        name: "UpdateMember",
        component: UpdateMember,
      },
    ],
  },
  {
    path: "/settings",
    name: "Settings",
    // component: Team,
    meta: { requiresAuth: true },
    children: [
      {
        path: "configurations",
        name: "Configurations",
        component: Configurations,
      },
      {
        path: "configurations/:name",
        name: "AddConfiguration",
        component: AddConfiguration,
      },
      {
        path: "mfa",
        name: "MFA",
        component: MfaSettings,
      },
    ],
  },
  {
    path: "/:catchAll(.*)",
    name: "PageNotFound",
    component: PageNotFound,
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export const openRoutes = [
  "/login",
  "/reset-password",
  "/forgot-password",
  "/mfa",
];

// Navigation guard to check authentication before accessing protected routes
router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem("token");

  // Extract the path and parameters
  const originalPath = to.path;

  const normalizedPath = originalPath
    .split("/")
    .map((segment, index, array) => {
      // If there's only one segment, return it as is
      if (array.length === 2) {
        if (segment !== "") {
          return segment.toLowerCase();
        }
      }
      // Convert only the segments of the path to lowercase, but keep the last segment (parameter) intact
      return index === array.length - 1 ? segment : segment.toLowerCase();
    })
    .join("/");

  if (to.path !== normalizedPath) {
    next(normalizedPath);
    return;
  }

  // Check if the route exists
  router.getRoutes().forEach((route) => {
    // Check if the route matches the path
    if (route.path === originalPath) return true;
    // Check for nested routes
    return (
      route.children &&
      route.children.some(
        (child) => child.path === originalPath.replace(`${route.path}/`, "")
      )
    );
  });

  // we dont have "/" route so we will redirect accordingly
  if (originalPath === "/") {
    if (isAuthenticated) {
      next("/dashboard");
    } else {
      next("/login");
    }
  }

  if (openRoutes.includes(originalPath)) {
    if (isAuthenticated) {
      next("/dashboard");
    } else {
      next();
    }
  } else if (!isAuthenticated) {
    next("/login"); // Redirect unauthenticated users to login
  } else {
    next();
  }
});

export default router;

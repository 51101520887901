import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

const contentBuilderStore = createStore({
  state: {
    items: [],
    enumItems: [],
    globalSettings: {
      webp: true,
    },
    user: null,
    dateType: null,
    goToMfa: false,
    compRecord: { record: {}, compId: "" }, // Existing state
    collectionRecord: { colRecord: {}, compId: "" }, // New state
  },
  mutations: {
    // Existing mutations
    ADD_ITEM(state, item) {
      state.items.push(item);
    },
    UPDATE_ITEM(state, { index, newItem }) {
      state.items = [newItem];
    },
    sort_Items(state, { index, newItem }) {
      state.items[0].componentData = newItem;
    },
    DELETE_ITEM(state, index) {
      if (index >= 0 && index < state.items.length) {
        state.items.splice(index, 1);
      }
    },
    CLEAR_ITEMS(state) {
      state.items = [];
    },
    SET_ENUM_ITEMS(state, items) {
      state.enumItems = [...items];
    },
    CLEAR_ENUM_ITEMS(state) {
      state.enumItems = [];
    },

    // Global Settings Mutations
    SET_GLOBAL_SETTINGS(state, settings) {
      state.globalSettings = { ...settings };
    },
    UPDATE_GLOBAL_SETTING(state, { key, value }) {
      state.globalSettings[key] = value;
    },
    CLEAR_GLOBAL_SETTINGS(state) {
      state.globalSettings = {};
    },

    // user state
    SET_USER_DATA(state, data) {
      state.user = Object.assign({}, data);
    },
    CLEAR_USER_DATA(state) {
      state.user = null;
    },

    SET_DATE_TYPE(state, items) {
      state.dateType = items;
    },
    CLEAR_DATE_TYPE(state) {
      state.dateType = null;
    },

    // mfa scanned
    SET_GO_TO_MFA(state, data) {
      state.goToMfa = data;
    },

    // compRecord Mutations
    SET_COMP_RECORD(state, record) {
      state.compRecord = { ...record };
    },
    UPDATE_COMP_RECORD(state, record) {
      state.compRecord = { ...state.compRecord, ...record };
    },
    CLEAR_COMP_RECORD(state) {
      state.compRecord = {};
    },

    // collectionRecord Mutations
    SET_COLLECTION_RECORD(state, record) {
      state.collectionRecord = { ...record };
    },
    UPDATE_COLLECTION_RECORD(state, record) {
      state.collectionRecord = { ...state.collectionRecord, ...record };
    },
    CLEAR_COLLECTION_RECORD(state) {
      state.collectionRecord = {};
    },
  },
  actions: {
    // Existing actions
    addItem({ commit }, item) {
      commit("ADD_ITEM", item);
    },
    updateItem({ commit }, payload) {
      commit("UPDATE_ITEM", payload);
    },
    deleteItem({ commit }, index) {
      commit("DELETE_ITEM", index);
    },
    clearItems({ commit }) {
      commit("CLEAR_ITEMS");
    },
    sortItems({ commit }, index) {
      commit("sort_Items", index);
    },
    setEnumItems({ commit }, items) {
      commit("SET_ENUM_ITEMS", items);
    },
    clearEnumItems({ commit }) {
      commit("CLEAR_ENUM_ITEMS");
    },

    // Global Settings Actions
    setGlobalSettings({ commit }, settings) {
      commit("SET_GLOBAL_SETTINGS", settings);
    },
    updateGlobalSetting({ commit }, payload) {
      commit("UPDATE_GLOBAL_SETTING", payload);
    },
    clearGlobalSettings({ commit }) {
      commit("CLEAR_GLOBAL_SETTINGS");
    },

    // user state
    setUserData({ commit }, payload) {
      commit("SET_USER_DATA", payload);
    },
    clearUserData({ commit }) {
      commit("CLEAR_USER_DATA");
    },

    setDateType({ commit }, items) {
      commit("SET_DATE_TYPE", items);
    },
    clearDateType({ commit }) {
      commit("CLEAR_DATE_TYPE");
    },

    setGoToMfa({ commit }, payload) {
      commit("SET_GO_TO_MFA", payload);
    },

    // compRecord Actions
    setCompRecord({ commit }, record) {
      commit("SET_COMP_RECORD", record);
    },
    updateCompRecord({ commit }, record) {
      commit("UPDATE_COMP_RECORD", record);
    },
    clearCompRecord({ commit }) {
      commit("CLEAR_COMP_RECORD");
    },

    // collectionRecord Actions
    setCollectionRecord({ commit }, record) {
      commit("SET_COLLECTION_RECORD", record);
    },
    updateCollectionRecord({ commit }, record) {
      commit("UPDATE_COLLECTION_RECORD", record);
    },
    clearCollectionRecord({ commit }) {
      commit("CLEAR_COLLECTION_RECORD");
    },
  },
  getters: {
    // Existing getters
    items: (state) => state.items,
    enumItems: (state) => state.enumItems,
    itemCount: (state) => state.items.length,
    dateType: (state) => state.dateType,

    // Global Settings Getters
    globalSettings: (state) => state.globalSettings,
    getGlobalSetting: (state) => (key) => state.globalSettings[key],

    // user state
    user: (state) => state.user,

    goToMfa: (state) => state.goToMfa,

    // compRecord Getters
    compRecord: (state) => state.compRecord,

    // collectionRecord Getters
    collectionRecord: (state) => state.collectionRecord,
  },
  plugins: [createPersistedState()],
});

export default contentBuilderStore;

<script setup>
import { formatFileSize } from "@/services/util";
import { computed } from "vue";

const props = defineProps({
  fileName: String,
  size: String,
  isCompleted: Boolean,
  initialStage: Boolean,
});

const formattedFileSize = computed(() => {
  const sizeInBytes = parseInt(props.size, 10);
  return formatFileSize(sizeInBytes);
});
</script>

<template>
  <div class="file-card">
    <div class="file-icon">
      <v-icon class="" name="bi-file-text" scale="1.5" />
    </div>
    <div>
      <p class="file-name">
        {{
          fileName.length > 30 ? fileName.substring(0, 30) + "..." : fileName
        }}
      </p>
      <p class="file-size">{{ formattedFileSize }}</p>
    </div>
    <span class="icon">
      <v-icon
        v-if="isCompleted"
        name="bi-check-circle"
        scale="1.2"
        fill="#198754"
      />
      <v-icon
        v-if="initialStage"
        name="pr-spinner"
        scale="1.2"
        animation="spin"
      />
    </span>
  </div>
</template>

<style scoped>
p {
  margin: 0%;
}
.file-card {
  margin-top: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0.3rem;
  width: 96%;
  align-items: center;
  display: flex;
  gap: 0.5rem;
  border-radius: 5px;
}

.file-name {
  font-weight: bold;
  font-size: small;
}
.file-size {
  font-size: x-small;
}
.icon {
  margin-inline-start: auto;
}
</style>
